

.info-panel {
  flex: 1;
  position: fixed;
  right: -600px; /* Start the panel off-screen to the right */
  background-color: white;
  z-index: 3;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  padding: 0;
  height: 100%;
  width: 600px;
  overflow-y: auto;
  transition: right 0.3s ease-in-out; /* Add a smooth transition effect */
}

.info-panel.open {
  right: 0; /* Slide the panel in from the right when the "open" class is added */
}

.info-panel.close {
  right: -600px; /* Slide the panel out to the right when the "close" class is added */
}

/* mobile */
@media (max-width: 770px) {
  .info-panel {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    right: 0px;
    box-shadow: none;
    transition: none;
    top: 0;
    z-index: 1000;
  /*  right: -100%; /* Start the panel off-screen to the right on mobile */
  }
  
  .info-panel.open {
    display: block;
  }
  
  .info-panel.close {
    display: none;
  }
}

/* desktop */
@media (min-width: 770px) {
  .info-panel {
    max-width: 600px;
    height: 100%;
    overflow-y: scroll;
  }
}

/* HEADER */ 
.info-header {
  display: flex;
  align-items: center;
  background-color: var(--very-light-gray);
  justify-content: flex-start; /* If you want to space out children elements */
  width: 100%; /* Ensure the header takes full width of its parent */
  padding: 10px;
  box-sizing: border-box; /* Include padding and borders in the element's total width */
}

.collapse-button {
  background: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5em;
}

.station-info h2,
.station-info p {
  margin: 0;
  margin-left: 0px;
  padding: 0;
  padding-left: 15px;
  text-align: left;
}

.station-info h2 {
  padding-top: 5px;
}


/* BODY INFO */
.image-container {
  position: relative;
  width: auto;
  max-width: 100%;
  margin: 5px;
  padding: 5px;
}


.image-container img {
  width: 100%;
  height: 270px; /* Set a fixed height for the image */
  object-fit: cover; /* Cover the container without distorting the image */
  object-position: center; /* Align the image in the center, useful for cropping */
  border-radius: 8px 8px 0 0; /* Rounded corners at the top */
  display: block; /* Eliminates any space below the image */
}

.station-description {
  position: relative;
  width: auto;
  max-width: 100%;
  margin: 10px;
  padding: 10px;
  font-size: 15px;
  text-align: left;
  border: 1px solid var(--gray-border); /* Light gray border */
  border-radius: 5px;
}

/* ARROW SELECTORS ON IMAGE */

.arrow-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
}

.arrow {
  background-color: rgba(51, 51, 51, 0.9); /* Adjust color and opacity as needed */
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px; /* Adjust size as needed */
  height: 45px; /* Adjust size as needed */
  cursor: pointer;
  font-size: 24px; /* Adjust arrow size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px; /* Adjust spacing between arrows */
}

.left-arrow {
  margin-right: 0px; /* Adjust spacing between arrows */
}


.book-button {
  position: absolute;
  bottom: 20px; /* Adjust as needed for exact positioning from the bottom */
  right: 20px; /* Adjust as needed for exact positioning from the right */
  background-color: var(--blue);/* Amtrak's brand color or any color you prefer */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer; /* Indicates that the element is clickable */
  z-index: 10;
  font-weight: bold; /* Optional: makes the text bold */
}

.book-button:hover {
  background-color: #0056b3; /* Darker shade for hover state */
}

.station-icon {
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
}

.station-details {
  display: flex;
  align-items: center; /* This will vertically center align the items */
  gap: 4px; /* Adjust the space between items if necessary */
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
}


/* TRAINS  */
.trains-container {
  position: relative;
  width: auto;
  max-width: 100%;
  margin: 10px;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  font-size: 14px;
  /* font-weight: bold; */
}
.trains-title {
  font-size: 14px;
  font-weight: bold;
}

/* tool tip */ 
.tooltip-header {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-header .tooltiptext-header {
  font-size: 12px;
  visibility: hidden;
  width: 120px !important;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Adjust the distance from the image */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip horizontally */
}

.tooltip-header:hover .tooltiptext-header {
  visibility: visible;
}



.description {
  padding: 0px;
  margin: 5px 0px;
}

.activity {
margin: 0px;
}

.activity-number {
  font-weight: bold;
}

h3 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.info-panel-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 10px;
  font-weight: bold;
font-size: 18px;
}

/* just used for the go back button */
.filter-button {
  display: flex;
  justify-content: center;
  overflow-x: visible;
  align-items: center;
  padding: 8px 16px;
  margin: 0px 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background-color: white;
  border: 1px solid #ddd;
} 

 .filter-button.set {
  color: #2479e9;
  background-color: #e8f0fe;
  border: 1px solid #e8f0fe;
}

.info-panel-map {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;

  margin: 10px;
  padding: 10px;
  padding-top: 0px;
  margin-top: 0px;
  font-size: 15px;
  text-align: left;
 /* border: 1px solid #d3d3d3; /* Light gray border */
  /* height: 511px; */
  padding-bottom: 0px;
 background: white;
}

.info-panel-map2 {
  /* position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* width: auto; */
  /* max-height: 400px; */
  /* min-height: 350px; */

  margin: 10px;
  padding: 10px;
  padding-top: 0px;
  margin-top: 0px;
  font-size: 15px;
  text-align: left;
 /* border: 1px solid #d3d3d3; /* Light gray border */
  /* height: 511px; */
  padding-bottom: 0px;
 background: white;
}

.info-panel-map p {
  font-style: italic;
}

.leaflet-control-zoom {
  padding-top: 25px;
}
