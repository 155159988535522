
/* trains  */


.trains-container {
     position: relative;
    width: auto;
    max-width: 100%;
    margin: 10px;
    padding: 10px;
    text-align: left;
    border-radius: 5px;
    font-size: 14px; 
    margin-bottom: 0px;
    padding-bottom: 0px;
  
  }
  
  
  .card {
    background-color: var(--very-light-gray);
    border-radius: 8px;
    margin-top: 5px;
   /*  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
   /*  border: 1px solid #D3D3D3; /* Light gray border */ 
    margin-bottom: 16px;
    overflow: hidden;
  }
  
  .card-header {
    background-color: var(--very-light-gray);
    padding-top: 10px;
    padding-bottom: 2px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
  
  }
  
  .route-names {
    font-style: italic;
    font-weight: normal; 
  }
  
  .card-body {
    display: flex;
    justify-content: space-between;
    /* justify-items: center; */
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  
  
  
  .time-range {
    display: flex;
    align-items: center;
    
    font-size: 16px;
    font-weight: bold;
  }

  .date{
    display: flex;
    flex-direction: column;
    font-weight: normal;
    padding-right: 0px;
    width: 30px;
    font-size: 10px;
    /* background-color: var(--light-blue); */
    /* font-style: italic; */
  }
  
  .transfers {
    font-size: 14px;
    font-weight: bold;
    position: relative;
  }
  
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltiptext {
    visibility: hidden;
    min-width: 200px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 4px;
    position: absolute;
    z-index: 1;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
    font-weight: normal;
    font-size: 12px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .elapsed-time {
    font-size: 14px;
    font-weight: bold;
  }
  
  .trains-title {
    font-size: 14px;
    font-weight: bold;
  }

  