
/* DATE PICKER */


/* .custom-range-picker {
    background-color: var(--very-light-gray);
    color: black;
    border: none;
    height: 40px;
    font-size: 16px;
    width: 350px;
  } */


/* Adjust the separator style */


/* Customize the input field styles */
.MuiOutlinedInput-root {
  font-size: 14px;
  padding: 0px;
  border: none;
  border-radius: 4px;
  background-color: var(--very-light-gray);
}

.MuiMultiInputDateRangeField-root {
  margin-left: 0px !important;
  margin: 0px !important;
}

.MuiInputBase-root {
  margin-right: 0px; 
  font-size: 12px;
  
}

.MuiOutlinedInput-input {
  padding: 5px !important;
}

.MuiOutlinedInput-input {
  border-radius: 4px;
  border: 1px solid #ddd !important;
  min-width: 65px !important;
  /* max-width: 135px !important; */
  font-size: 14px !important;
  height: 28px !important;
  padding: 5px 10px !important;
  margin: 0px !important;
  /* border: none !important; */
  /* border: 1px solid lightgray !important; */
} 

.MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
}

.MuiMultiInputDateRangeField-separator {
  /* display: none !important; */
  color: gray !important;
  padding: 0px !important;
  margin: 0px !important;
  display: none !important;

}

/* .css-1x58qyc-MuiTypography-root-MuiMultiInputDateRangeField-separator {
display: none !important;
} */

/* MuiInputBase-input-MuiOutlinedInput-input */

  /* adjust calendar icon to the left */
  /* .custom-range-picker .MuiInputAdornment-root {
    order: -1;
    margin-right: 8px;
    color: black;
  } */

  /* .date-range-input {
    display: flex;
    align-items: center;
    gap: 5px;
  } */
  
 




  /* adjust the calendar icon to the left */
  /* .custom-range-picker .ant-picker-suffix {
    order: -1;
    margin-right: 8px;
    color: black;
  }
   */

  /* remember to set the hover */

  
  /* RADIO BUTTONS */
  
  .custom-radio-group {
    color: black;
    /* width: 500px; */
    /* height: 10px; */
  }

  .ant-radio-button-wrapper {
    font-size: 12px;
    /* height: 10px; */
  }

  .ant-radio-button {
      /* height: 10px; */
  }
  
  .ant-radio-button-checked {
    background-color: var(--very-light-gray);
    border-color: var(--gray-border);
    color: black;
  }
  
  .custom-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: var(--very-light-gray);
    border-color: var(--gray-border);
    color: black;
    font-weight: 600; /* Semi-bold */
  }
  
  .custom-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--very-light-gray);
    font-weight: 600; /* Semi-bold */
  }
  
  /* selected one */
  .custom-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: black;
    font-weight: 600; /* Semi-bold */
    box-shadow: none;
    border: 1px solid var(--gray-border);
  }
  
  /* not selected one */
  .custom-radio-group .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
    color: black;
  }
  

  /* BOOK BUTTON */ 

  .book-button2 {
    /* position: relative; */
    background-color: var(--blue); /* Amtrak's brand color or any color you prefer */
    color: white;
    padding: 10px 20px;
    /* margin: 10px 20px; */
    /* margin-top: 0px; */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    cursor: pointer; /* Indicates that the element is clickable */
    font-weight: bold; /* Optional: makes the text bold */
    height: 40px;
  }
  
  .book-button2:hover {
    background-color: #0056b3; /* Darker shade for hover state */
  }


  /* TRAVELERS */ 

  .party {
      padding-left: 4px;
      font-size: 12px;
  }
  .travelers-text {
      font-weight: 600; /* semi bold */
  }

  .button-plus {
      border-radius: 4px;
      border: 1px solid var(--gray-border);
      background-color: white;
      cursor: pointer; 
      min-height: 20px;
    min-width: 20px;
  }

  .button-minus {
    border-radius: 4px;
    border: 1px solid var(--gray-border);
    background-color: white;
    cursor: pointer; 
    min-height: 20px;
    min-width: 20px;
}

.button-minus.disabled {
    color: var(--disabled);        /* Light grey color to indicate disabled state */
   /*  cursor: not-allowed;  Cursor shows the action is not allowed */
  }


  /* CONTAINERS */ 
  .booking {
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 10px;
      margin-top: 0px;
      padding-top: 0px;
  }

  .booking-bottom {
      padding-top: 10px;
  }

  .booking-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
  }

  .booking-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

  }

  /* mobile */
@media (max-width: 770px) {
    .custom-range-picker {
      width: 100%;
      font-size: 12px;
    }
    
    .book-button2 {
        padding: 5px 10px;
        margin: 0px 5px;
        min-width: 115px;
    } 

  }

  /* adjust the calendar icon to the left */
