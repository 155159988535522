
/* 
.custom-tooltip {
  padding: 0px;
  border: 0px;
  font-family: Arial, sans-serif;
  margin: 0; 
  padding: 0px;
  border-radius: 8px;
  pointer-events: none;
} 
*/

/* .custom-tooltip:hover {
  box-shadow: 0 2px 4px rgba(77, 146, 237, 0.4);
  border-color: blue;
} */

/* .tooltip-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 0px;
  margin: 0px;
 
} 
*/

/* Adds margin at the top to separate from the image */
.tooltip-content h2 {
  margin: 0px 0px 0px 0px; 
  font-size: 12px;
  margin: 0px;
  padding: 0px;
}

.leaflet-popup-content-wrapper:hover {
  box-shadow: 0 2px 4px rgba(77, 146, 237, 0.4);
}

.leaflet-popup-tip:hover {
  /* padding: 0px; */
}


.leaflet-tooltip:hover { 
  border: 2px solid var(--blue);
}



.tooltip-content.origin {
  justify-content: center;
  align-items: center;
}

.leaflet-tooltip {
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* .tooltip-info {
  margin-top: 4px;
} */





/* POPUP FILES */
/* .leaflet-popup {
  padding: 0px;
  border: 0px;
  
} */


/* 
.leaflet-popup-content-wrapper {
  box-shadow: 0 2px 4px rgba(0,0,0, 0.4);
  height: 30px;
  box-sizing: border-box;
  padding: 1px;
  margin: 0px;
  min-width: 50px;
  padding-left: 0px;
  
} */



/* .custom-popup {
    font-family: Arial, sans-serif;
    margin: 0; 
    padding: 0px;
    min-width: 50px;

  } */
  
  /*
  .popup-image-container img {
    width: calc(100% + 46px); 
    height: 145px; 
    object-fit: cover; 
    object-position: center;
    display: block; 
    border-radius: 8px 8px 0 0; 
    margin-left: -21px;
    margin-top: -14px;
  }
  */

  /* .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;
    margin-left: -5px;
    margin-top: -9px;
    
  }
  
  .popup-content h2 {
    margin: 0px 0px 0px 0px; 
    font-size: 12px;
    margin: 0px;
    padding: 0px;
  }
   */
  /*
  .popup-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    font-size: 12px;
  }
  */
  /* 
  .popup-link {
    display: inline-block;
    padding-left: 0;
    color: #0078d7;
    text-decoration: none; 
    font-size: 01em;
    white-space: nowrap;
  }

  .see-more {
    margin-left: auto; 
  }
  
  */

  .leaflet-tooltip.selected {
    border: 2px solid var(--blue);
  }
