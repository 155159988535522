/* .App {
  text-align: center;
  position: relative;  This makes sure that the button is positioned relative to the App container 
}

 */


/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */


.App {
  height: 100vh; /* Fallback for browsers that don't support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  /* overflow: hidden; */
}


.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  
}

.content {
  
  /* note that removing flex 1 makes it not show up on desktop */
  flex: 1; 
  /* flex: 1; grow (fill any avialable space) */
  /* flex: 2, shrink */
  /* flex: 3 basis e.g. auto is just the regular size */
  padding: 0px;
 /* Add this to hide any overflowing content */
  /* overflow: hidden;  */
}

/* desktop*/
@media (min-width: 770px) {
  .flex-container {
    flex-direction: row;
    
  }

  .content {
    margin: 0px; 
    border: 0px;
    border-left: 1px solid #ddd;
    flex: 1;
  }
}

/* mobile */
@media (max-width: 770px) {

  .flex-container {
    flex-direction: column;
   
  }
  .content {
    border: none;
    padding: 0px;
    flex: 1;
    margin-top: 0px;
    
    /* Adjust this value based on the width of the top navigation */
    /* margin-top: 60px; */
     /* overflow: hidden;  */
     
    /* height: calc(100vh - 60px); */
  }
}
/* 

body {
  margin: 0;
  font-family: sans-serif;
}

@media (max-width: 770px) {
  body {
    padding-top: 60px;
  }
} */


:root {
  --off-white: #f0f0f0;
  --very-light-gray: #f5f5f5;
  --medium-light-gray: #dddddd;
  --gray-border: #ddd;
  --hover-gray: #eee;
  --dark-gray: #767676;
  --disabled: #888888;
  --very-light-blue: #EEF8FF;
  --light-blue: #e8f0fe;
  --blue: #2479e9;
}