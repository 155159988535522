/* fix: raise the left nav menu item */


/* top nav FOR MOBILE */ 

.top-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 48px !important;
  background-color: white;
  z-index: 1000; /* just above the search */
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-bottom: 1px solid var(--gray-border);
}


.top-nav-header{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
}

/* menu icon is always shown in the left-nav */ 
.menu-icon-top {
    margin-right: 10px;
    cursor: pointer;
    position: absolute; /* Set position to absolute */
    left: 27px; /* Align to the left of the parent element */
  }


  .logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .logo-text {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .logo-image {
    height: 40px;
  }

 

.top-nav.open-top {
  /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.6); */
  margin: 0px;
    padding: 0px;
    border: 0px;
}

.nav-items-container-top {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  /* position: absolute; */
  position: fixed;
   top: 48px; 
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--gray-border);
  animation: slideIn 0.3s ease-in-out;
  z-index: 3;
  

}
/* grand parent element is collapsed-top */
.collapsed-top .nav-items-container-top {
    display: none;
}

.nav-items-container-top.collapsed {
  align-items: center;
}

.nav-item-top {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
    /* top right bottom left */

    /* top + bottom .... left + right */
    margin: 0px 0px;
    padding: 0px 24px;
    border-bottom: 1px solid var(--gray-border);
    z-index: 3;
  }

  .nav-item-top:hover,
.nav-item-top.active {
  background-color: var(--very-light-gray);
}


.nav-items-container-top.collapsed {
    display: flex;
  }
  

/* SHARED */ 
.nav-container {
    padding: 0px;
    margin: 0px;
    height: 48px;
    /* this is where the height is actually controlled */ 
}
/* 
.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
} */

.logo-text {
  margin-right: 5px;
}

.logo-image {
  height: 24px;
}

.nav-text {
    margin-left: 10px;
  }
  
  .nav-text.active {
    font-weight: bold;
  }


/* LEFT NAV for desktop */ 
.left-nav {
    width: 160px;
    height: 100vh;
    background-color: white;
    transition: width 0.3s;
    z-index: 3;
   
}

.collapsed-left {
    width: 70px;
}

/* header containers the logo & menu-icon ... just menu when collapsed */
.left-nav-header {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-left: 20px;
    height: 15px
  }

  .logo-container-left  {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  /* menu icon is always shown in the left-nav */ 
  .menu-icon-left {
    /* margin-right: 10px; */
    padding: 8px;
    cursor: pointer;
  }
  .menu-icon-left:hover{
    background-color: var(--very-light-gray);
    border-radius: 4px;
  }
  
  /* holds the set of items */ 
.nav-items-container-left {

}

.nav-item-left:hover,
.nav-item-left.active {
  background-color: var(--very-light-gray);
}

.nav-item-left {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    margin: 8px 16px;
    padding: 0px 8px;
    border-radius: 4px;
}
  

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.top-nav-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px !important;
  background-color: white;
  z-index: 1000; /* just above the search */
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-bottom: 1px solid var(--gray-border);
}

.top-nav-header-desktop{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  }
  


/* delete the below */
  /* .nav.mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ddd;
    z-index: 1000;
  } */


  /* note that a space in between css naming indicates it must be a descendent */ 

.menu-icon-top {
  padding: 8px;
}

.menu-icon-top:hover {
   background-color: var(--very-light-gray);
   border-radius: 4px;
  }
