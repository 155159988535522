.search-container {
    position: relative;
  }
  
  .search {
    position: relative;
    padding: 0px;
    width: 300px; /* Example width - adjust as needed */
    margin: 5px;
    box-sizing: border-box;
  }
  
  .search-form {
    width: 100%;
  }
  
  .search-input {
  width: 100%;
  /* top right bottom left */
  padding: 10px 10px 10px 10px;
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 40px;
  font-size: 16px;
  }

   /* mobile */
  @media (max-width: 770px) {
     .search {
      position: relative;
     /*  z-index: 2; */
      width: calc(100% - 0px);
      margin: 10px;
      box-sizing: border-box;
      padding-right: 13px;
    }
    /* this solves the problem of the overhang */
    .search.destination {
      left: -2px;
    }
  
    .search-container {
      position: relative;
      width: 100%;
      height: 50px;
      background-color: white;
      margin: 0px;
      padding: 0px;
      border: 0px;
      padding-bottom: 5px;
    }
  
  /* controls the selected text color */ 
  .search-input {
    /* background-color: white;
    border: 0px ; */
  }
    .origin-search-Popover.destination{
      width: 95%;
    }
  }
  /* end mobile */ 

  .search.destination{
      margin-left: 2px;
      padding-left: 2px;
    }

  .search-results {
  position: relative;
  background-color: white;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border: 1px solid var(--gray-border);
  }
  
  .autocomplete-option {
  cursor: pointer;
  padding: 10px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: space-between;
  align-items: center; /* vertical */ 
  justify-items: left;
  box-sizing: border-box;
  width: 100%;
 
  }

  .search-input.destination::placeholder {
    color: black;
  }
  
  .search-input.destination.disabled::placeholder {
    color: var(--disabled);
  }
  

  .search-icon-destination.disabled {
    color: var(--disabled); /* Mid-range gray */
  }
  .autocomplete-option:hover {
  background-color: var(--very-light-gray);
  }
  .result-icon {
    display: flex;
    left: 10px;
    width: 20px;
    height: 20px;
    padding: 1px;
  }
  
  .station-set {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: start;
    width: 100%;
    padding-left: 5px;
  }
  
  /* Continue with styling for .top-row, .station-name, .station-code, and .bottom-row */
  .search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  }
  /* this styles the individual option components */
  .top-row {
  display: flex;
  margin: 0px; /* Space between top and bottom rows */
  padding: 0px;
  }
  
  .station-name {
  font-weight: bold;
  font-size: 16px;
  }
  
  .station-code {
  font-weight: normal;
  font-size: 14px;
  }
  
  .bottom-row {
  display: flex;
  font-size: 14px; /* Matching the font size of the station code for consistency */
  }
  
 