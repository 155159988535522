.about-page {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 00px;

}

.about-container {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding: 20px;
  
}

.about-text {
  flex: 1;
  min-width: 300px; /* Maintain a minimum width for text content */
  text-align: left;
  padding-right: 20px;
}

.about-text h4 {
  font-size: 14px;
  margin-bottom: 0.5em;
  margin-left: 8px;
  font-weight: normal;
}

.about-text h1 {
  font-size: 48px;
  margin-bottom: 0.5em;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.about-text p {
  font-size: 16px;
  margin-left: 8px;
}

.about-image {
  flex: 1;
  min-width: 300px; /* Maintain a minimum width for image content */
  text-align: center; /* Center the image horizontally */
  padding: 20px;
}

.about-image img {
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover; /* Fill the available space while maintaining aspect ratio */
  border-radius: 50%;
}

@media (max-width: 770px) {

  .about-image {
    flex: 1;
    min-width: 300px; /* Maintain a minimum width for image content */
    text-align: center; /* Center the image horizontally */
    padding: 10px;
    padding-bottom: 0px;
  }
 
  .about-image img {
    width: 100%;
    max-width: 320px;
    max-height: 320px;
    object-fit: cover; /* Fill the available space while maintaining aspect ratio */
    /* border-radius: 50%; */
  }
}

.value-section {
  background-color: var(--very-light-blue);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.value-row {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.value-column {
  flex: 1;
  min-width: 300px; /* Maintain a minimum width for columns */
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.value-column h2 {
  font-size: 40px;
  color: #000;
  margin: 0px 0;
}

.value-column p {
  font-size: 16px;
  color: #000;
}

.value-divider {
  height: 2px;
  background-color: var(--gray-border);
  margin: 20px auto;
  width: 90%;
}

.value-column img {
  width: 100%;
  max-width: 300px; /* Limit the maximum width of the image */
  max-height: 300px; /* Limit the maximum height of the image */
  object-fit: cover; /* Fill the available space while maintaining aspect ratio */
}

.trainy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.donate-page {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 00px;

}

.donate-container {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding: 20px;
}
.donate-column {
  flex: 1;
  min-width: 300px; /* Maintain a minimum width for columns */
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.donate-column.info {
  max-width: 300px;
}

.donate-column.coffee {
  min-width: 350px;
  
}

.feedback-page {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 00px;
}
.feedback-container {
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 10px;
  height: 800px;
}

.googleform {
  width: 100%;
  /* width: 640px; */
  min-width: 350px;
  max-width: min(calc(100%), 800px);

}