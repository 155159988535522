


.map-container-explore {
    flex: 1;
    overflow: hidden; /* Prevent scrolling on the map container */
}

.map-div {
    /*  it inherits 16 margin somehow */
      flex: 1;
      /* z-index: 1; */
      /* flex-grow: 1; */
      margin: 0px 0px 0px 0px;
      border: 0px 0px 0px 0px;

  }

  .map-div p{
      padding: 00px;
      margin: 0px;
  }


/* mobile */
  @media (max-width: 770px) {
    .main-content-explore {
      
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    } 

    .map-container-explore {
        display: flex;
        overflow: hidden; /* Prevent scrolling on the map container */
        flex: 1;
      
      } 

      .map-div {
          flex: 1;
      }

}

/* desktop */
@media (min-width: 770px) {
    .main-content-explore {
      
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    } 

    .map-container-explore {
        display: flex;
        overflow: hidden; /* Prevent scrolling on the map container */
        flex: 1;
       
      } 

      .map-div {
          flex: 1;
      }

}
