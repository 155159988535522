/* filters.css */

.all-filters-button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* top&bottom right&left */
  padding: 8px 16px;
  margin: 0px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #2479e9;
  font-weight: bold;
  background-color: white;
  border: 1px solid var(--gray-border);
}

.all-filters-button:hover {
  background-color: #f7f8f8;
}

.all-filters-button img {
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

.stations-filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin: 0px 5px;
  background-color: var(--off-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background-color: white;
  border: 1px solid var(--gray-border);
  padding-right: 8px;
}

.stations-filter-button.set {
  color: #2479e9;
  background-color: var(--light-blue);
  border: 1px solid var(--light-blue);
}

.stations-filter-button img {
  height: 18px;
  width: 18px;
}
.stations-filter-button:hover {
  /* horizontal offset, then vertical offset, then blur radius, then color in this case black, followed by opacity */
  box-shadow: 0 2px 4px rgba(77, 146, 237, 0.4); /* Adjust values as needed */
}

.stations-filter-button .from {
  font-weight: normal;
}

.filter-button {
  display: flex;
  justify-content: center;
  overflow-x: visible;
  align-items: center;
  padding: 8px 16px;
  margin: 0px 5px;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background-color: white;
  border: 1px solid var(--gray-border);;
  padding-right: 8px;
}

.filter-button.set {
  color: var(--blue);
  background-color: white;
  border: 1px solid var(--gray-border);;
}

.filter-button img {
  height: 18px;
  width: 18px;
}
.filter-button:hover {
  /* horizontal offset, then vertical offset, then blur radius, then color in this case black, followed by opacity */
  box-shadow: 0 2px 4px rgba(77, 146, 237, 0.4); /* Adjust values as needed */
}

.code {
    font-weight: normal;
}

.filters-container {
  display: flex;
  padding-top: 5px;
  /* overflow-x: scroll; */
  /* overflow-y: scroll; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.filters-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.filters-Popover {
  position: absolute;
  z-index: 3;
  background-color: white;
  border: 1px solid var(--gray-border);;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 360px;
}

.filters-Popover-Portal {
  position: absolute;
  /* width: calc(100% - 90px); */
 /* height: 100vh;  */
 min-height:  calc(100% - 18px);
  top: 0;
 left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid var(--gray-border);
  border-radius: 0px;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

/* mobile */
@media (max-width: 770px) {
  .filters-Popover {
    left: 0px;
   
  }
}

.origin-search-Popover {
  position: absolute;
  z-index: 3;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  padding: 0px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); */
  width: 360px;
}

.section-header {
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
}
.section-header.origin {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 0px;
}

.placename {
  font-size: 14px;
  padding: 10px;
  color: #767676;
}

.origin-destination {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--very-light-gray);
}

.origin-destination .selected {
  font-weight: bold;
  border-bottom: 2px solid black;
}

/* stations card here */

.station-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid var(--gray-border);
  margin: 5px;
}

.left-two-container {
  display: flex;
  flex-direction: row;
}

.radio-group {
  padding: 10px;
}

.radio-button-class {
  width: 18px;
  margin-right: 10px;
}

.station-name-body {
  display: flex;
  flex-direction: column;
}

.station-name-top {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  display: row;
}
.station-name-top-code {
  font-weight: normal;
}

.station-address {
  font-size: 10px;
  color: var(--dark-gray);
}

.station-distance {
  display: flex;
  flex-direction: column;
  width: 65px;
}
.station-distance-top {
  font-size: 14px;
  font-weight: bold;
}
.station-distance-bottom {
  font-size: 12px;
  color: var(--dark-gray);
}

.show-more {
  padding: 5px;
  color: var(--blue);
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.duration-text {
  padding: 10px;
}

.filters-portal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-weight: bold;
  font-size: 18px;
  }
  
  .filters-portal-header .filters-label {
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
  font-size: 18px;
  }

  .filters-portal-footer {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 10px;
    font-weight: bold;
  font-size: 18px;
  }

  .divider {
    border-top: 1px solid var(--gray-border);
  }

  .filters-Popover.long {
    /* max-height: min(100vh, 600px); */
    max-height: 500px;
    overflow-y: auto;
  }

